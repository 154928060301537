import React from "react"
import styled from "styled-components"
import { useState } from "react"
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from "react-google-recaptcha-v3"
export const ContactForm = () => {
  //const { executeRecaptcha } = useGoogleReCaptcha()
  const [token, setToken] = useState();
 
  const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue)
    const handleChange = e => {
        setValue(e.target.value)
    }
    return {
        value,
        onChange: handleChange,
    }
  }
  const email = useFormInput('')
const message = useFormInput('')
const name = useFormInput('')
const phone = useFormInput('')
// this will be important later
const emailVal = email.value
const messageVal = message.value
const phoneVal = phone.value
const nameVal = name.value
const handleSubmit = e => {
  e.preventDefault() //--> prevent the page from reloading on form submit
  //const form = e.target
  //setServerState({ submitting: true })
  //const data = new FormData(form)
  
  /*if (!executeRecaptcha) {
    return
  } */
  if(token !='') {
  const data = JSON.stringify({"email": emailVal, "message": messageVal, "phone": phoneVal, "name": nameVal, "g-recaptcha-response": token });
  //data.append("g-recaptcha-response", token);
  console.log(data, "data")

  fetch("https://getform.io/f/dd6b7fbe-c5b3-4a89-8413-8a893a16fd26", {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-type": "application/json",
    },
    body: data,
  }) .then( rèsponse => {
    window.location.href='/tak-for-din-henvendelse/';

 
  }

  )
}
  
}
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lebx3sbAAAAAD15XsvFnFbtqz7knamrNCWDMJSx">
    <Form
     
      method="POST"
      onSubmit={handleSubmit}
    >
      <ContactHeading>Would you like to collaborate with us?</ContactHeading>
      <ContactText>Fill out the form, and we will contact you.</ContactText>
      <Column>
        <Input type="text" name="name" required {...name} id="the-name" placeholder="Name" />
        <Input type="email" name="email" required {...email} id="the-email" placeholder="Email" />
        <Input type="tel" name="phone" required {...phone} id="the-phone" placeholder="Phone" />
      </Column>
      <Column>
        <Textarea
          name="message"
          {...message}
          required
          id="the-message"
          placeholder="Any relevant information about your company"
        />
        <Submit type="submit" />
        <GoogleReCaptcha
              onVerify={token => {
                setToken(token)
              }}
            />
      </Column>
    </Form>
    </GoogleReCaptchaProvider>
  )
}
const Column = styled.div`
  @media screen and (min-width: 600px) {
    width: 50%;
    padding: 1em;
    float: left;
  }
`
const Form = styled.form`
  width: 90%;
  max-width: 800px;
  margin: 50px auto;
  text-align: center;
  margin-bottom: 0;
  @media screen and (min-width: 600px) {
    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }
`
const ContactHeading = styled.h2`
  font-size: 24px;
  font-weight: 500;
  color: #eff1ef;
  text-align: center;
`
const ContactText = styled.p`
  color: #eff1ef;
  font-size: 16px;
  text-align: center;
  padding-left: 0 !important;
`
const Input = styled.input`
  display: block;
  width: 100%;
  padding: 0.5em;
  font-size: 20px;
  background-color: rgba(10, 11, 16, 0.5);
  border: solid 1px #b2b0b2;
  border-radius: 4px;
  resize: vertical;
  margin-bottom: 1em;
  color: #cccacc;
`
const Textarea = styled.textarea`
  display: block;
  width: 100%;
  padding: 0.5em;
  font-size: 20px;
  border-radius: 4px;
  background-color: rgba(10, 11, 16, 0.5);
  border: solid 1px #b2b0b2;
  resize: vertical;
  min-height: 180px;
  color: #cccacc;
  font-weight: 400;
`
const Submit = styled.input.attrs({
  type: "submit",
  value: "Yes please, contact me",
})`
  float: right;
  margin: 1em 0 0;
  border-radius: 4px;
  background: #c6af6d;
  padding: 10px 22px;
  color: #eff1ef;
  font-size: 18px;
  font-weight: 400;
  border: none;
  transition: all 0.3s ease-out;
  cursor: pointer;
  @media (max-width: 992px) {
    margin-bottom: 75px;
  }
`